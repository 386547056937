<template>
    <div id="secure">
        <h1>Auftragsübersicht</h1>
        Hier siehst Du alle Deine PAWs<br>&nbsp;<br>&nbsp;<br>&nbsp;
        <div class="tabs">
            <md-tabs class="md-primary" md-alignment="centered" md-swipeable @md-changed="onTabChange">
                <md-tab id="tab-offered" v-bind:md-label="'Neu ('+offeredPaws.length+')'">
                    <div v-if="offeredPaws && offeredPaws.length > 0" class="tablecontainer">
                        <table class="table">
                            <tr>
                                <th>Bezeichnung</th>
                                <th>Aktionen</th>
                            </tr>
                            <tr v-for="paw in offeredPaws" v-bind:key="paw.pawId">
                                <td>{{ paw.description }}</td>
                                <td>
                                    <md-button class="md-raised" v-on:click="details(paw.pawId)">Details</md-button>
                                    <md-button class="md-raised" v-on:click="claim(paw.pawId)">Annehmen</md-button>
                                    <md-button class="md-raised" v-on:click="reject(paw.pawId)">Ablehnen</md-button>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div v-else>
                        <br>
                        - aktuell keine neuen Aufträge -
                        <br>&nbsp;<br>
                    </div>
                </md-tab>
                <md-tab id="tab-claimed" v-bind:md-label="'aktuelle Aufträge ('+claimedPaws.length+')'">
                    <div v-if="claimedPaws && claimedPaws.length > 0" class="tablecontainer">
                        <table class="table">
                            <tr>
                                <th>Bezeichnung</th>
                                <th>Aktionen</th>
                            </tr>
                            <tr v-for="paw in claimedPaws" v-bind:key="paw.pawId">
                                <td>{{ paw.description }}</td>
                                <td>
                                    <md-button class="md-raised" v-on:click="details(paw.pawId)">Details</md-button>
                                    <md-button class="md-raised" v-on:click="markAsDone(paw.pawId)">Fertig</md-button>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div v-else>
                        <br>
                        - aktuell keine angenommenen Aufträge -
                        <br>&nbsp;<br>
                    </div>
                </md-tab>
                <md-tab id="tab-done" v-bind:md-label="'Archiv ('+archivedPaws.length+')'">
                    <div v-if="archivedPaws && archivedPaws.length > 0" class="tablecontainer">
                        <table class="table">
                            <tr>
                                <th>Bezeichnung</th>
                                <th>Aktionen</th>
                            </tr>
                            <tr v-for="paw in archivedPaws" v-bind:key="paw.pawId">
                                <td>{{ paw.description }}</td>
                                <td>
                                    <md-button class="md-raised" v-on:click="details(paw.pawId)">Details</md-button>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div v-else>
                        <br>
                        - noch keine Aufträge als erledigt markiert -
                        <br>&nbsp;<br>
                    </div>
                </md-tab>
            </md-tabs>
        </div>
        <Partner_Rework_Reject v-bind:active="rework_reject.active" :action="rework_reject.action"
                               v-bind:paw-id="rework_reject.pawId" @onClose="rework_reject.active = false"/>
    </div>
</template>

<script>
import Partner_Rework_Reject from "./Partner_Rework_Reject";
import {PAWStatus} from "../helpers/PAWstates";

export default {
    name: 'Partner_Dashboard',
    props: {
    },
    data: () => ({
        status: 0,
        paws_display: [],
        rework_reject: {
            active: false,
            action: "",
            pawId: ""
        }
    }),
    components: {Partner_Rework_Reject},
    mounted() {
    },
    computed: {
        offeredPaws: function (){
            let l = []
            for(let p of this.$store.state.paws){
                if(p.status === PAWStatus.offered){
                    l.push(p)
                }
            }
            return l
        },
        claimedPaws: function (){
            let l = []
            for(let p of this.$store.state.paws){
                if(p.status === PAWStatus.claimed){
                    l.push(p)
                }
            }
            return l
        },
        archivedPaws: function (){
            let l = []
            for(let p of this.$store.state.paws){
                if(p.status === PAWStatus.done){
                    l.push(p)
                }
            }
            return l
        },
    },
    methods: {
        reject(id) {
            this.rework_reject.pawId = id
            this.rework_reject.action = "reject"
            this.rework_reject.active = true
        },
        claim(id) {
            this.$store.dispatch("claimPaw", id).then(() => {
            })
        },
        markAsDone(id) {
            this.$store.dispatch("markAsDone", id).then(() => {
            })
        },
        details(id_paw) {
            this.$router.push("/partner/paw/detail/" + id_paw)
        },
        onTabChange(tabId) {
            if (tabId === "tab-offered") {
                this.status = 1
            } else if (tabId === "tab-claimed") {
                this.status = 3
            } else if (tabId === "tab-done") {
                this.status = 5
            }
        }
    }
}
</script>

<style scoped>
#secure {
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    padding: 20px;
    margin-top: 10px;
    align-content: center;
}

div.tabs {
    width: 80%;
    margin: auto;
}

div.tablecontainer {
    text-align: center;
}

table.table {
    display: inline-block;
    border-collapse: collapse;
}

table.table > tr > th {
    font-size: large;
    padding: 10px;
}

table.table > tr > td {
    padding: 10px;
}

tr:nth-child(odd) {
    background: #EEE;
}

tr:nth-child(even) {
    background: #FFF;
}
</style>
