<template>
    <div>
        <md-dialog v-bind:md-active="active" @md-opened="opened" @md-closed="close" @md-clicked-outside="close">
            <md-dialog-title>{{ actionString }}:</md-dialog-title>

            <form novalidate @submit.prevent="send">
                <md-dialog-content>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-size-100">
                            <span v-if="action === 'rework'">Gib an, was der Nutzer ändern muss, damit Du den Auftrag annehmen kannst.</span>
                            <span v-if="action === 'reject'">Gib an, warum Du den Auftrag ablehnst.</span>
                        </div>
                        <div class="md-layout-item md-small-size-100">
                            <md-field>
                                <label for="reason">Begründung</label>
                                <md-textarea id="reason" ref="reason" v-model="form.reason" :disabled="sending"
                                             autocomplete="off" name="reason"></md-textarea>
                            </md-field>
                        </div>
                    </div>
                </md-dialog-content>

                <md-dialog-actions>
                    <md-button :disabled="sending" class="md-accent md-raised" @click="close">Abbrechen</md-button>
                    <md-button :disabled="sending" class="md-primary md-raised" type="submit">Bestätigen</md-button>
                </md-dialog-actions>
            </form>
        </md-dialog>
    </div>
</template>

<script>
const roles = ["admin", "partner", "user"]

export default {
    name: "Partner_Rework_Reject",
    props: {
        active: Boolean,
        action: {
            type: String,
            validator: function (value) {
                return ['rework', 'reject', ""].indexOf(value) !== -1
            }
        },
        pawId: {
            type: String,
        }
    },
    data: () => ({
        form: {
            reason: null
        },
    }),
    computed: {
        sending: function () {
            return this.$store.state.rxtx > 0
        },
        actionString: function () {
            if(this.action === "rework")
                return "Nacharbeiten"
            else if (this.action === "reject")
                return "Ablehnung"
            return ""
        }
    },
    methods: {
        opened() {
            const t = this
            this.$nextTick(() => {
                document.getElementById(t.$refs["reason"].$el.id).focus();
            })
        },
        send() {
            let req = {
                pawId: this.pawId,
                reason: this.form.reason
            };
            const dispatcher = this.action + "Paw"
            this.$store.dispatch(dispatcher, req).then(() => {
                this.$router.push("/" + roles[this.$store.state.user.role] + "/dashboard/")
                this.clearForm();
                this.close()
            });
        },
        clearForm() {
            this.form.reason = "";
        },
        close(){
            this.$emit("onClose")
        }
    }
};
</script>
